import padilogo from '../../assets/images/padi-logo-transparent.png';
import { CSSTransition } from "react-transition-group";
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import { Email, WorkOutlined } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
const Register = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const history = useHistory()


    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("/api/users/register", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    let data = await res.json();
                    console.log(data);
                    setError(data?.errorMessage);
                    setTimeout(() => {
                        setError(null);
                    }, [5000])
                }
                else {
                    history.push('/register-success');
                }
            })
            .catch(err => {
                console.log('err', err.response.data)
            })
    }

    return (<CSSTransition timeout={1500} classNames='auth'>
        <div className='vertical-center authentification'>
            <div className='text-center' id='login'>
                <img src={padilogo} id='logo-login' height="150" text-align="center"></img>
                <div className='alert alert-danger' id='error' style={{ display: error === null ? 'none' : '' }}>{error}</div>
                <div className='block-content block block-400'>
                    <form onSubmit={handleSubmit} id='login-form'>
                        <fieldset>
                            <label><Person /></label>
                            <input type='text' placeholder='PACs Username' name='username' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Lock /></label>
                            <input type='password' placeholder='Password' name='password' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Email /></label>
                            <input type='email' placeholder='Email' name='email' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><WorkOutlined /></label>
                            <input type='text' placeholder='Department' name='department' onChange={handleChange} required />
                        </fieldset>

                        <div className='r-and-f'>


                            <div style={{ fontSize: '1rem' }}>
                                Already have account ?
                                <Link to={'/'}>
                                    <label style={{ fontStyle: 'italic', cursor: 'pointer' }}> Login</label>
                                </Link>
                            </div>
                        </div>

                        <button name='connexion' type='submit' className='login-btn' > Register </button>


                    </form>
                </div>
            </div>
        </div>
    </CSSTransition>)
}

export default Register;